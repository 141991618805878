import React, { useMemo } from "react";
import MultiSelect from "@shared/MultiSelect";

export default function SelectAccount({
  accounts,
  selectedAccounts,
  setSelectedAccounts,
  superUser,
  salesRepUser,
  selectedOffices,
  setSelectedOffices,
}) {
  const offices = useMemo(() => {
    if (selectedAccounts.length) {
      const value = selectedAccounts
        .map((account) => accounts.find((a) => a.id == account)?.offices)
        .flat();

      return value;
    }

    return [];
  }, [selectedAccounts]);

  if (superUser) {
    return null;
  }

  if (salesRepUser) {
    return (
      <div className="column">
        <label htmlFor="account_ids">Accounts</label>
        <MultiSelect
          options={accounts.map((account) => ({
            label: account.name,
            value: account.id,
          }))}
          selectedValues={selectedAccounts}
          onChange={(selectedValues) => {
            setSelectedAccounts(selectedValues);
          }}
          name="account_ids"
        />
      </div>
    );
  }

  return (
    <div className="column">
      <label htmlFor="account_ids">Account</label>
      <select
        value={selectedAccounts[0]}
        name="account_ids"
        onChange={(e) => setSelectedAccounts([e.target.value])}
      >
        <option value="">Select Account</option>
        {accounts.map((account) => (
          <option key={account.id} value={account.id}>
            {account.name}
          </option>
        ))}
      </select>

      {(offices.length && (
        <div>
          <label htmlFor="office_ids">Offices</label>
          <MultiSelect
            required
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
            selectedValues={selectedOffices}
            onChange={(selectedValues) => {
              setSelectedOffices(selectedValues);
            }}
            name="office_ids"
          />
        </div>
      )) ||
        null}
    </div>
  );
}
