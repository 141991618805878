import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import "./style.css";
import BreadCrumb from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import { useNavigate } from "react-router-dom";
import PendingIcon from "@shared/Icon/pending";
import ShippedIcon from "@shared/Icon/shipped";
import DeliveredIcon from "@shared/Icon/delivered";
import CancelledIcon from "@shared/Icon/cancelled";

const searchOptions = {
  keys: [
    "order_number",
    "purchase_orders.doctor",
    "purchase_order_products.serial_number",
    "purchase_order_products.tracking_number",
    "purchase_orders.purchase_order_number",
  ],
};

export default function Orders() {
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [filteredOffice, setFilteredOffice] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filteredByOffice, setFilteredByOffice] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showOrdersSince, setShowOrdersSince] = useState("90");

  useEffect(() => {
    setLoading(true);
    api.get(`/orders?since=${showOrdersSince}`).then((response) => {
      setOrders(response);
      setFilteredOrders(response);
      setLoading(false);
    });
  }, [showOrdersSince]);

  useEffect(() => {
    if (orders) {
      setFuse(new Fuse(orders, searchOptions));
    }
  }, [orders]);

  // Combine filters and search
  useEffect(() => {
    if (filteredByOffice && filteredBySearch) {
      setFilteredOrders(
        filteredByOffice.filter((order) => filteredBySearch.includes(order))
      );
    } else if (filteredByOffice) {
      setFilteredOrders(filteredByOffice);
    } else if (filteredBySearch) {
      setFilteredOrders(filteredBySearch);
    } else {
      setFilteredOrders(orders);
    }
  }, [filteredByOffice, filteredBySearch]);

  useEffect(() => {
    if (filteredOffice) {
      setSearchPlaceholder(`Search in ${filteredOffice.name}`);
    } else {
      setSearchPlaceholder("Search");
    }
  }, [filteredOffice]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  const handleFilterByOffice = (event) => {
    const officeId = event.target.value;
    if (!officeId) {
      setFilteredByOffice(null);
      setFilteredOffice(null);
      return;
    }

    setFilteredOffice(user.offices.find((office) => office.id == officeId));
    const filtered = orders.filter((order) => order.office.id == officeId);
    setFilteredByOffice(filtered);
  };

  const handleFilterByDate = (event) => {
    const date = event.target.value;

    setShowOrdersSince(date);
  };

  if (loading || !user) {
    return <Loading />;
  }

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Orders"]} />
      <div className="row">
        <div className="column column-50">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column">
          <select onChange={handleFilterByOffice}>
            <option value="">Filter by Office</option>
            {user?.offices?.map((office) => (
              <option key={office?.id} value={office?.id}>
                {office?.name}
              </option>
            ))}
          </select>
        </div>
        <div
          className="column"
          title="Hide orders that are delivered and shipped at over x days"
        >
          <select onChange={handleFilterByDate} value={showOrdersSince}>
            <option value="180">Hide orders over 180 days</option>
            <option value="90">Hide orders over 90 days</option>
            <option value="60">Hide orders over 60 days</option>
            <option value="30">Hide orders over 30 days</option>
            <option value="0">Show all</option>
          </select>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>PO Numbers</th>
            <th>Order Number</th>
            <th>Office</th>
            <th>Date Ordered</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders?.map((order) => (
            <tr
              key={order.id}
              className="orders--action-row"
              onClick={() => navigate(`/admin/orders/${order.id}`)}
            >
              <td>
                {order.purchase_orders.map((po) => (
                  <div key={po.id} title={po.status}>
                    {
                      {
                        processing: <PendingIcon />,
                        shipped: <ShippedIcon />,
                        delivered: <DeliveredIcon />,
                        cancelled: <CancelledIcon />,
                      }[po.status]
                    }
                    <span>{po.purchase_order_number}</span>
                  </div>
                ))}
              </td>
              <td>{order.order_number}</td>
              <td>{order.office.name}</td>
              <td>{formatDate(order.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {showOrdersSince !== "0" && (
        <div
          className="text-center"
          style={{ margin: "20px  0", color: "var(--primary-color)" }}
        >
          Orders that are marked as delivered, that were created over{" "}
          {showOrdersSince} days ago will not be shown.
        </div>
      )}
    </div>
  );
}
