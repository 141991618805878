import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import "./style.css";
import BreadCrumb from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import { Link, useNavigate } from "react-router-dom";
import { formatPrice } from "@util";

const searchOptions = {
  keys: ["name", "product_sizes.size", "product_sizes.price"],
};

export default function Products() {
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("/products").then((response) => {
      setProducts(response);
      setFilteredProducts(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (products) {
      setFuse(new Fuse(products, searchOptions));
    }
  }, [products]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredProducts(filteredBySearch);
    } else {
      setFilteredProducts(products);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  if (loading || !user) {
    return <Loading />;
  }

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Products"]} />
      <div className="row">
        <div className="column column">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column-25">
          <Link to="/admin/products/create" className="button">
            Create a New Product
          </Link>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Account</th>
            <th>Pricing</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts?.map((product) => (
            <tr
              key={product.id}
              className="orders--action-row"
              onClick={() => navigate(`/admin/products/${product.id}`)}
            >
              <td>{product.name}</td>
              <td>
                {product.account_products.map((ap) => {
                  return <div>{ap.account.name}</div>;
                })}
              </td>
              <td>
                {product.product_sizes.map((size) => (
                  <div key={size.id}>
                    {size.size} - {formatPrice(size.price)}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
